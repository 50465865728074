<template>
    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->

        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Front-Header> </Front-Header>

                <section class="shop_page search-listing">
                    <div class="container px-0">
                        <div class="row no-gutters">
                            <div class="col-lg-3 col-md-12">
                                <div class="sidebar-wrapper" style="border-bottom: 0px none rgb(119, 119, 119);"> <a
                                        href="javascript:void(0);" v-on:click="close_filter" id="close_filter"><i
                                            class="fa fa-times"></i></a>
                                    <form v-on:submit.prevent="submit">
                                        <div class="scroll_filters">

                                            <div class="widget">
                                                <h3 class="widget-title">
                                                    Categories
                                                </h3>
                                                <div class="collapse show" id="widget-body-1" style="">
                                                    <div class="widget-body">
                                                        <div class="form-group">
                                                            <label :for="category.name" v-for="category in categories"
                                                                :key="category.id">
                                                                <input type="checkbox" name="category[]"
                                                                    :value="category.id" class="category">{{
                                                                        category.name
                                                                    }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- End .widget-body -->
                                                </div>
                                                <!-- End .collapse -->
                                            </div>
                                            <!-- End .widget -->
                                            <hr>

                                            <div class="widget mt-4">
                                                <h3 class="widget-title">Offer Type</h3>
                                                <div class="collapse show" id="widget-body-2">
                                                    <div class="widget-body">
                                                        <div class="form-group">
                                                            <label for="get_free" v-for="(offerType, index) in offerTypes"
                                                                :key="index">
                                                                <input type="checkbox" name="offer_types[]"
                                                                    :value="offerType" class="offer_types">{{ offerType
                                                                    }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- End .widget-body -->
                                                </div>
                                                <!-- End .collapse -->
                                            </div>
                                            <!-- End .widget -->
                                            <hr>

                                            <div class="widget mt-4">
                                                <h3 class="widget-title">Offers</h3>
                                                <div class="collapse show" id="widget-body-3">
                                                    <div class="widget-body">
                                                        <div class="form-group">
                                                            <label for="new" v-for="(offer1, index) in offers1"
                                                                :key="index">
                                                                <input type="checkbox" name="offers[]" :value="offer1"
                                                                    class="offers">{{ offer1 }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- End .widget-body -->
                                                </div>
                                                <!-- End .collapse -->
                                            </div>
                                            <!-- End .widget -->
                                            <hr>

                                            <div class="widget mt-4">
                                                <h3 class="widget-title">
                                                    Amenities
                                                </h3>
                                                <div class="collapse show" id="widget-body-3">
                                                    <div class="widget-body">
                                                        <div class="form-group">
                                                            <label for="new" v-for="(amenitie, index) in amenities"
                                                                :key="index">
                                                                <input type="checkbox" name="amenities[]" :value="amenitie"
                                                                    class="amenities">{{ amenitie
                                                                    }}</label>
                                                        </div>
                                                    </div>
                                                    <!-- End .widget-body -->
                                                </div>
                                                <!-- End .collapse -->
                                            </div>
                                            <!-- End .widget -->
                                            <hr>
                                            <div class="widget mt-4">
                                                <h3 class="widget-title">
                                                    Tag
                                                </h3>
                                                <div class="collapse show" id="widget-body-3">
                                                    <div class="widget-body">
                                                        <div class="form-group">
                                                            <label for="new" v-for="(extratd, index) in extra_tagdata"
                                                                :key="index">
                                                                <input type="checkbox" name="extra_tagdata[]"
                                                                    :value="extratd" class="extratd">{{ extratd
                                                                    }}</label>
                                                        </div>
                                                    </div>
                                                    <!-- End .widget-body -->
                                                </div>
                                                <!-- End .collapse -->
                                            </div>
                                            <!-- End .widget -->
                                            <hr>

                                        </div>
                                        <div class="clear_filter">
                                            <button type="submit" class="btn btn-primary">Apply</button>
                                            <input type="reset" v-on:click="getCompanies()" value="Clear"
                                                class="btn btn-dark">
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-12 search-listing-right">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row top_filter" id="top_filter">
                                            <div class="col-md-9">
                                                <div class="search_bar">
                                                    <form v-on:submit.prevent="submit">
                                                        <input type="text" placeholder="Search by place, name & more"
                                                            v-model.trim="name"
                                                            :class="{ 'is-invalid': validationStatus(name) }"
                                                            class="form-control form-control-lg">
                                                        <!--<div v-if="!$v.name.required" class="invalid-feedback">The name field is required.</div>-->
                                                        <button type="submit">Search</button>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="col-md-3"> <a href="#" v-on:click="mobile_filter_toggle"
                                                    class="mobile_filter_toggle"><i class="fa fa-sliders-h"></i> More
                                                    Filters</a>
                                                <div class="map_toggle"> <i class="fa fa-map-marker-alt"></i>
                                                    &nbsp;&nbsp;Map
                                                    <div class="custom-switch">
                                                        <input type="checkbox" id="switch" v-on:click="switchTab()" class="switch">
                                                        <label for="switch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 search-bottom-row-head">
                                        <div class="row search-bottom-row">
                                            <div class="col-md-4 col-sm-6" v-for="company in companies" :key="company.id">
                                                <router-link :to="{ path: '/single-company/' + company.id }">
                                                    <div class="package">
                                                        <div class="main_img"> <img :src="company.background_image">
                                                            <h3>{{ company.name }}</h3>
                                                            <h5>{{ company.category }}</h5> <img class="pakage-logo"
                                                                :src="company.image">
                                                        </div>
                                                        <div class="package_content">
                                                            <!--<p>Lorem ipsum dolor sit amet, consectet adipiscing elit. </p>-->
                                                            <p class="category d-flex flex-wrap"> <span class="text"
                                                                    v-for="(tag, index) in company.tags" :key="index">{{
                                                                        tag
                                                                    }}</span></p>
                                                            <ul>
                                                                <li v-for="(offer, index) in company.offers" :key="index">
                                                                    {{
                                                                        offer
                                                                    }}</li>
                                                            </ul>
                                                        </div>
                                                    </div>





                                                </router-link>
                                            </div>
                                        </div>

                                        <div class="map-filter">
                                            <div class="mapouter">
                                                <div class="gmap_canvas">
                                                    <div id="map" class="map newclassmap"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>
            </div>
        </div>
    </body>
</template>

<script>

import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import mapboxgl from "mapbox-gl";
import $ from 'jquery'
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import { db } from '@/main'

export default {

    components: {
        'Front-Header': Header,
        'Front-Footer': Footer,
        'Loader': Loader,
    },
    data: function () {
        return {
            name: '',
            category: [],
            categories: [],
            companies: [],
            offersType: [],
            offers: [],
            offers2: [],
            finaloffers: {},
            tags: [],
            tags2: [],
            extra_tags: [],
            extra_tags2: [],
            extra_tagdata: [],
            finaltags: {},
            offerTypes: [],
            offers1: [],
            amenities: [],
            cid1: [],
            cid2: [],
            cid3: [],
            cid4: [],
            data: [],
            accessToken: "pk.eyJ1IjoiaW50cm9zYnMiLCJhIjoiY2t2MHU4bWE4M2h1OTMwczcxMm03YmM0eSJ9.ocDaQEjuvL3rUd50oaB6Qw",
            center: [localStorage.getItem("sluga"), localStorage.getItem("slugaa")],
            zoom: 8,
            scrollZoom: true,
            listing: "",
            link: "",
            details: "",
            feature: "",
            activeItem: "",
            currentFeature: "",
            marker: "",
            // images: []
        }
    },
    metaInfo() {
        //alert();
        return {
            title: "Search",
            meta: [
                { name: 'description', content: 'search' },
            ]
        }
    },
    validations: {
        name: {},
        category: {},
    },
    created: function () {
        this.getCategories();
        this.getCompanies();
        this.getOfferType();
        // this.addMarkers();
        // this.createPopUp();
        this.valid();


    },
    mounted() {
        this.createMap();
        $('#switch').on('click', function () {
            if ($(this).is(":checked")) {
                $(".map-filter").addClass('active');
                $(".search-bottom-row").addClass('activon');
                $(".search-bottom-row > div").removeClass("col-md-4").addClass('col-md-12 active-row');
            } else {
                $(".map-filter").removeClass('active');
                $(".search-bottom-row").removeClass('activon');
                $(".search-bottom-row > div").addClass("col-md-4").removeClass('col-md-12 active-row');
            }
        });
    },

    methods: {
        switchTab() {
            this.createMap();
        },

        async createMap() {
            const stores = { 'type': 'FeatureCollection' };
            let features = new Array();
            let images = new Array();
            console.log('working test')
            var documentd = await db.collection("companies").where("country", "==", localStorage.getItem("ciddata"))
                .get();

            //.then((querySnapshot) => {
            documentd.forEach((doc) => {
                if (doc.data().stores != "undefined" && doc.data().stores != undefined) { features.push(doc.data().stores); images.push({logo : doc.data().image}); }
                //});

            })

            stores.features = features;
            stores.features.forEach(function (store, i) {
                store.properties.id = i;
                store.properties.logo = images[i].logo;
            });

            // console.log(stores.features[0].geometry.coordinates);
            // console.log(this.center);
            console.log("stores");
            this.map = new mapboxgl.Map({
                accessToken: this.accessToken,
                container: "map",
                style: "mapbox://styles/introsbs/cky8pprlp74ya14p67qe7stm4",
                minzoom: 1,
                center: stores.features[0].geometry.coordinates,
                zoom: this.zoom,
                hash: false,
                scrollZoom: false
            });
            this.map.on("move", () => {
                this.center = this.map.getCenter();
            });
            this.map.on('mouseenter', () => {

            });
            this.map.on("zoom", () => {
                this.zoom = this.map.getZoom();
                // console.log(this.zoom);
            });
            this.map.on('load', () => {
                this.map.addSource('places', {
                    'type': 'geojson',
                    'data': stores
                });
                this.map.addControl(new mapboxgl.NavigationControl());
                this.addMarkers(stores);
            });
            this.map.addControl(new mapboxgl.FullscreenControl());

        },
        addMarkers(stores) {
            for (this.marker of stores.features) {
                // console.log(this.marker);
                // console.log("this.marker");
                this.el = document.createElement('div');
                this.el.id = 'marker-' + this.marker.geometry.id;
                this.el.className = 'marker';
                this.el.setAttribute("data-id", this.marker.geometry.coordinates);
                this.el.setAttribute("cid", this.marker.properties.cid);
                this.el.setAttribute("pid", this.marker.properties.id);
                this.el.setAttribute("location", this.marker.properties.city);
                new mapboxgl.Marker(this.el, { offset: [0, -23] })
                    .setLngLat(this.marker.geometry.coordinates)
                    .addTo(this.map);
                // console.log(this.marker);
                //console.log("this.marker");
                this.el.addEventListener('click', (event) => {
                    const ddd = event.target.getAttribute("data-id");
                    const dddip = event.target.getAttribute("pid");
                    const cid = event.target.getAttribute("cid");
                    const location = event.target.getAttribute("location");
                    const myArray = ddd.split(",");
                    const ddw = myArray[0];
                    const ddw1 = myArray[1];
                    this.map.flyTo({
                        center: [ddw, ddw1],
                        zoom: 15
                    });
                    this.marker.properties.id
                    for (this.marker of stores.features) {
                        if (dddip == this.marker.properties.id) {

                            this.valid(location);
                            // alert(this.marker.geometry.coordinates)
                            const popUps = document.getElementsByClassName('mapboxgl-popup');
                            const search_bottom_row = document.getElementsByClassName('search-bottom-row')[0].firstChild.className = "current"; 
                            // console.log(search_bottom_row);
                            if (popUps[0]) popUps[0].remove();
                            new mapboxgl.Popup({ closeOnClick: false })
                                .setLngLat(this.marker.geometry.coordinates)
                                .setHTML(
                                    "<a target='_blank' href='/single-company/" + cid + "'><h3 class='c_name'>" + this.marker.properties.company_name + "</h3><img class='logimg' src=" + this.marker.properties.logo + "><h4>" + this.marker.properties.email + "</h4><h4 class='phon_num'>" + this.marker.properties.phone + "</h4><h4 class='web_site'>" + this.marker.properties.website + "</h4></a>"
                                )
                                .addTo(this.map);
                        }
                    }

                });

            }
        },

        mobile_filter_toggle() {
            // alert('yes');
            $('.sidebar-wrapper').addClass('active');
        },
        close_filter() {
            //alert('no');
            $('.sidebar-wrapper').removeClass('active');
        },

        validationStatus: function (validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        async submit() {

            this.companies = [];
            this.company_ids = [];

            var selectedCategory = [];
            $('.category:checked').each(function (i) {
                selectedCategory[i] = $(this).val();
            });

            //console.log('selectedCategory --'+selectedCategory);

            var selectedOfferTypes = [];
            $('.offer_types:checked').each(function (i) {
                selectedOfferTypes[i] = $(this).val();
            });

            // console.log('selectedOfferTypes --'+selectedOfferTypes);

            var selectedOffers = [];
            $('.offers:checked').each(function (i) {
                selectedOffers[i] = $(this).val();
            });
            // console.log('selectedOffers --'+selectedOffers);

            var selectedAmenities = [];
            $('.amenities:checked').each(function (i) {
                selectedAmenities[i] = $(this).val();
            });

            // get extra tag
            var selectedextra_tags = [];
            $('.extratd:checked').each(function (i) {

                selectedextra_tags[i] = $(this).val();
            });

            //console.log('selectedextra_tags --'+selectedextra_tags);

            this.cid1 = [];
            this.cid2 = [];
            this.cid3 = [];
            this.cid4 = [];
            this.data = [];

            if (selectedOfferTypes.length != 0) {
                await db.collection("offers").where('feature', 'array-contains-any', selectedOfferTypes).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.cid1.push(doc.data().company);
                    });
                });
                this.data.push(this.cid1);
            }

            if (selectedOffers.length != 0) {
                await db.collection("offers").where('offers', 'array-contains-any', selectedOffers).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.cid2.push(doc.data().company);
                    });
                });
                this.data.push(this.cid2);
            }

            if (selectedAmenities.length != 0) {
                await db.collection("offers").where('tag', 'array-contains-any', selectedAmenities).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.cid3.push(doc.data().company);
                    });
                });
                this.data.push(this.cid3);
            }
            //tag
            if (selectedextra_tags.length != 0) {
                await db.collection("offers").where('extra_tags', 'array-contains-any', selectedextra_tags).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.cid4.push(doc.data().company);
                    });
                });
                this.data.push(this.cid4);
            }

            //console.log('star');
            //console.log(this.cid1);
            //console.log(this.cid2);
            //console.log(this.cid3);
            //console.log('end');

            if (this.cid1.length != 0 || this.cid2.length != 0 || this.cid3.length != 0 || this.cid4.length != 0) {
                this.company_ids = this.data.reduce((a, b) => a.filter(c => b.includes(c)));
                //console.log(this.company_ids);
            }

            var query = db.collection("companies")
            // Search by Category
            if (selectedCategory.length != 0) {
                query = query.where("category_id", "array-contains-any", selectedCategory)
            }
            query.get()
                .then((querySnapshot) => {

                    querySnapshot.forEach((doc) => {
                        if (localStorage.getItem("ciddata") == doc.data().country) {
                            var name = doc.data().name.toLowerCase();
                            var category = doc.data().category;
                            //var listcountry=[];

                            category.map(function (value) {
                                // console.log(value.id)
                                let ref = db.collection('categories').doc(value.id)
                                ref.get()
                                    .then(snapshot => {  //DocSnapshot
                                        if (snapshot.exists) {
                                            this.post = snapshot.data().name.toLowerCase();
                                        }
                                    })
                            });
                            var country = doc.data().country.toLowerCase();
                            var location = doc.data().location.toLowerCase();
                            var st = this.name.toLowerCase();

                            //console.log(doc.id, " => ", doc.data());
                            //alert(st);
                            db.collection('offers').where("company", "==", doc.id).get().then((querySnapshot) => {

                                this.offers = [];
                                this.tags2 = [];

                                querySnapshot.forEach((docc) => {

                                    docc.data().feature.forEach((offersType) => {
                                        this.offers.push(offersType);
                                    });

                                    docc.data().offers.forEach((offers) => {
                                        this.offers.push(offers);
                                    });

                                    docc.data().tag.forEach((tags) => {
                                        this.tags2.push(tags);
                                    });

                                    this.offers = Array.from(new Set(this.offers));
                                    this.tags2 = Array.from(new Set(this.tags2));

                                });

                                // console.log( doc.data().category, " => ", this.offers);

                                const finaloffers = this.offers;
                                const finaltags = this.tags2;



                                //console.log('hhhu');
                                //console.log(doc.id);
                                //console.log(this.company_ids);
                                //console.log(this.company_ids.includes(doc.id));

                                // if company ids exist
                                if (this.company_ids.length != 0) {

                                    if (this.company_ids.includes(doc.id) === true) {

                                        this.companies.push({
                                            id: doc.id,
                                            name: doc.data().name,
                                            offers: finaloffers,
                                            tags: finaltags,
                                            lat: doc.data().lat,
                                            lng: doc.data().lng,
                                            image: doc.data().image,
                                            background_image: doc.data().background_image,
                                            created: doc.data().created,
                                        });

                                    }

                                } else {

                                    if (st) {
                                        //alert(st);
                                        //  Search by Name, Country, Location
                                        if (name.indexOf(st) > -1 || country.indexOf(st) > -1 || location.indexOf(st) > -1) {

                                            // console.log('ddd');

                                            this.companies.push({
                                                id: doc.id,

                                                name: doc.data().name,
                                                offers: finaloffers,
                                                tags: finaltags,
                                                lat: doc.data().lat,
                                                lng: doc.data().lng,
                                                image: doc.data().image,
                                                background_image: doc.data().background_image,
                                                created: doc.data().created,
                                            });
                                        }

                                    } else {

                                        this.companies.push({
                                            id: doc.id,
                                            name: doc.data().name,
                                            offers: finaloffers,
                                            tags: finaltags,
                                            lat: doc.data().lat,
                                            lng: doc.data().lng,
                                            image: doc.data().image,
                                            background_image: doc.data().background_image,
                                            created: doc.data().created,
                                        });

                                    }
                                }



                            });
                        }
                    });

                });
        },

        valid(te) {
            this.companies = [];
            db.collection("companies")
                .get()
                .then((querySnapshot) => {

                    querySnapshot.forEach((doc) => {
                        //alert(doc.data().country);
                        if (localStorage.getItem("ciddata") == doc.data().country) {
                            var name = doc.data().name.toLowerCase();
                            var country = doc.data().country.toLowerCase();
                            var location = String(doc.data().location.toLowerCase());



                            db.collection('offers').where("company", "==", doc.id).get().then((querySnapshot) => {

                                this.offersType = [];
                                this.offers = [];
                                this.tags2 = [];

                                querySnapshot.forEach((docc) => {

                                    docc.data().feature.forEach((offersType) => {
                                        this.offers.push(offersType);
                                    });

                                    docc.data().offers.forEach((offers) => {
                                        this.offers.push(offers);
                                    });

                                    docc.data().tag.forEach((tags) => {
                                        this.tags2.push(tags);
                                    });

                                    this.offers = Array.from(new Set(this.offers));
                                    this.tags2 = Array.from(new Set(this.tags2));

                                });

                                const finaloffers = this.offers;
                                const finaltags = this.tags2;



                                if (te) {
                                    //alert(te +"dfgd"+location);
                                    //  Search by Name, Country, Location
                                    if (name.indexOf(te) > -1 || country.indexOf(te) > -1 || location.indexOf(String(te.toLowerCase())) > -1) {
                                        this.companies.push({
                                            id: doc.id,
                                            name: doc.data().name,
                                            offers: finaloffers,
                                            tags: finaltags,
                                            lat: doc.data().lat,
                                            lng: doc.data().lng,
                                            image: doc.data().image,
                                            background_image: doc.data().background_image,
                                            created: doc.data().created,
                                        });
                                    }

                                }


                            });
                        }
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },

        getCategories() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            db.collection('categories')
                .where("checkedCategories", "==", 1)
                .get()
                .then((querySnapshot) => {

                    querySnapshot.forEach((doc) => {

                        this.categories.push({
                            id: doc.id,
                            name: doc.data().name,
                        });

                    })
                });
        },

        getOfferType() {

            db.collection('offers').get().then((querySnapshot) => {

                querySnapshot.forEach((docc) => {
                    if (localStorage.getItem("ciddata") == docc.data().country) {
                        // console.log(docc.data());
                        docc.data().feature.forEach((offers) => {
                            this.offerTypes.push(offers);
                        });

                        docc.data().offers.forEach((offers) => {
                            this.offers1.push(offers);
                        });

                        docc.data().tag.forEach((tags) => {
                            this.amenities.push(tags);
                        });
                        docc.data().extra_tags.forEach((extra_tags) => {
                            this.extra_tagdata.push(extra_tags);
                        });

                        this.offerTypes = Array.from(new Set(this.offerTypes));
                        this.offers1 = Array.from(new Set(this.offers1));
                        this.amenities = Array.from(new Set(this.amenities));
                        this.extra_tagdata = Array.from(new Set(this.extra_tagdata));
                    }

                });
            });
        },

        getCompanies() {

            /*this.markers =[ 
               { position:{lat: 53.462118, lng: -2.249097, lng1: -2.249097 }},
               { position: { lat: 53.464144, lng: -2.249828, lng1: -2.249097 }} 
             ]*/

            this.companies = [];

            db.collection("companies")
                .get()
                .then((querySnapshot) => {

                    querySnapshot.forEach((doc) => {
                        //alert(localStorage.getItem("ciddata") +"zsdsa"+ doc.data().location);
                        if (localStorage.getItem("ciddata") == doc.data().country) {

                            var name = doc.data().name.toLowerCase();
                            var country = doc.data().country.toLowerCase();
                            var location = doc.data().location.toLowerCase();

                            if (this.$route.query.search) {
                                this.name = this.$route.query.search;
                                var st = this.$route.query.search.toLowerCase();
                            }

                            db.collection('offers').where("company", "==", doc.id).get().then((querySnapshot) => {

                                this.offersType = [];
                                this.offers = [];
                                this.tags2 = [];

                                querySnapshot.forEach((docc) => {

                                    docc.data().feature.forEach((offersType) => {
                                        this.offers.push(offersType);
                                    });

                                    docc.data().offers.forEach((offers) => {
                                        this.offers.push(offers);
                                    });

                                    docc.data().tag.forEach((tags) => {
                                        this.tags2.push(tags);
                                    });

                                    this.offers = Array.from(new Set(this.offers));
                                    this.tags2 = Array.from(new Set(this.tags2));

                                });

                                const finaloffers = this.offers;
                                const finaltags = this.tags2;



                                if (st) {

                                    //  Search by Name, Country, Location
                                    if (name.indexOf(st) > -1 || country.indexOf(st) > -1 || location.indexOf(st) > -1 || this.post.indexOf(st) > -1) {

                                        this.companies.push({
                                            id: doc.id,
                                            name: doc.data().name,
                                            offers: finaloffers,
                                            tags: finaltags,
                                            lat: doc.data().lat,
                                            lng: doc.data().lng,
                                            image: doc.data().image,
                                            background_image: doc.data().background_image,
                                            created: doc.data().created,
                                        });
                                    }

                                } else {
                                    this.companies.push({
                                        id: doc.id,
                                        name: doc.data().name,
                                        offers: finaloffers,
                                        tags: finaltags,
                                        lat: doc.data().lat,
                                        lng: doc.data().lng,
                                        image: doc.data().image,
                                        background_image: doc.data().background_image,
                                        created: doc.data().created,
                                    });

                                }


                            });
                        }
                    });

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        }
    }
}




</script>
<style>
.logimg {
    width: 50px;
}

.search-listing {
    background: #FFFFFF;
}

section.shop_page.search-listing h3 a {
    font-size: 18px;
    position: relative;
    display: block;
}

.scroll_filters a {
    color: #000 !important;
}

.scroll_filters ul {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
}

.form-group {
    margin-bottom: 0;
}

form.filter_form,
div#widget-body-3 {
    margin-top: 20px;
}

.scroll_filters {
    position: relative;
}

.package {
    box-shadow: 0 0 25px 0px #f0f0f0;
}

.sidebar-wrapper .widget-title a:before {
    position: absolute;
    content: "\f077";
    right: 0;
    font-family: 'Font Awesome 5 free';
}

.cat-list li a {
    font-weight: 400;
    color: #2c2c2c !important;
}

.cat-list li {
    margin-bottom: 10px;
}

.widget .widget-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.widget {
    margin-bottom: 40px;
}

.sidebar-wrapper .form-group label {
    display: block;
    margin-bottom: 12px;
}

.sidebar-wrapper .form-group input[type="checkbox"] {
    float: right;
    zoom: 1.3;
}

.search-listing {
    background: #FFFFFF;
    border-top: 1px solid #f3f3f3;
    margin: 92px 0 0;
}

.search-listing-right {
    padding: 0 30px 30px !IMPORTANT;
}

.top_filter {
    padding: 20px 0 0;
}

.shop_page.search-listing input[type="reset"] {
    background: #03e1bc;
    color: #fff;
    font-size: larger;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 370px;
}

@media only screen and (min-width:1024px) {

    .sidebar-wrapper {
        background: #ffffff;
        padding: 0px;
        position: sticky;
        top: 0px;
        height: 100vh;
        overflow-y: auto;
        border-right: 1px solid #f1f1f1;
    }
}

.sidebar {
    position: absolute;
    width: 33.3333%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.map {
    position: absolute;
    left: 33.3333%;
    width: 66.6666%;
    top: 0;
    bottom: 0;
}

h1 {
    font-size: 22px;
    margin: 0;
    font-weight: 400;
    line-height: 20px;
    padding: 20px 2px;
}

a {
    color: #404040;
    text-decoration: none;
}

a:hover {
    color: #101010;
}

.heading {
    background: #fff;
    border-bottom: 1px solid #eee;
    min-height: 60px;
    line-height: 60px;
    padding: 0 10px;
    background-color: #00853e;
    color: #fff;
}

.listings {
    height: 100%;
    overflow: auto;
    padding-bottom: 60px;
}

.listings .item {
    display: block;
    border-bottom: 1px solid #eee;
    padding: 10px;
    text-decoration: none;
}

.listings .item:last-child {
    border-bottom: none;
}

.listings .item .title {
    display: block;
    color: #00853e;
    font-weight: 700;
}

.listings .item .title small {
    font-weight: 400;
}

.listings .item.active .title,
.listings .item .title:hover {
    color: #8cc63f;
}

.listings .item.active {
    background-color: #f8f8f8;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-left: 0;
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
    background: none;
}

::-webkit-scrollbar-thumb {
    background: #00853e;
    border-radius: 0;
}

.marker {
    border: none;
    cursor: pointer;
    height: 56px;
    width: 56px;
    background-image: url("../../../public/mapbox-marker-56x56.png");
}

/* Marker tweaks */
.mapboxgl-popup {
    padding-bottom: 50px;
}

.mapboxgl-popup-close-button {}

.mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
    padding: 0;
    width: 180px;
}

.mapboxgl-popup-content h3 {
    background: #03e1bc;
    color: #fff;
    margin: 0;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
}

.mapboxgl-popup-content h4 {
    margin: 0;
    padding: 10px;
    font-weight: 400;
}

.mapboxgl-popup-content div {
    padding: 10px;
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-content {
    margin-top: 15px;
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-tip {
    border-bottom-color: #91c949;
}

.map-filter.active {
    position: absolute;
    top: 15px;
    left: 39%;
    width: 57%;
}

section.shop_page.search-listing .package img:first-child {
    width: 100%;
    object-fit: cover;
}

.mapboxgl-canvas {
    height: 100% !important;
}

.map-filter.active .mapouter {
    width: 100% !important;
    max-width: inherit !important;
}

.mapboxgl-popup-content {
    text-align: center;
}

.mapboxgl-popup-content h3 {
    font-size: 16px;
}

.mapboxgl-popup-content h4 {
    font-size: 12px;
}

.mapboxgl-popup-close-button {
    margin-right: 4px;
    font-size: 25px;
    color: #fff;
    margin-top: -14px;
}

canvas.mapboxgl-canvas {
    width: 100% !important;
}

/* new css*/
.scroll_filters {
    height: 73vh;
}

.sidebar-wrapper .form-group label {
    margin-bottom: 4px;
}

.scroll_filters::-webkit-scrollbar {
    width: 10px !important;
}

.scroll_filters::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgb(209 221 219) !important;
}

.scroll_filters::-webkit-scrollbar-thumb {
    background-color: #03e1bcc2 !important;
    outline: 1px solid #03e1bc8f !important;
}

section.shop_page.search-listing .main_img img.pakage-logo {
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
    object-fit: contain;
}


.mapboxgl-popup-content .phon_num {
    padding: 0;
    color: #03e1bc;
    font-weight: 500;
}

.mapboxgl-popup-content {

    width: auto;
    min-width: 180px;
}

.mapboxgl-popup-content .web_site {
    padding-top: 5px;
    padding-bottom: 20px;
    color: #03e1bc;
    font-weight: 200;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
}

.mapboxgl-popup-content img.logimg {
    margin-top: 10px;
}

@media (min-width:768px) {
    .row.search-bottom-row.activon {
        max-height: 80vh;
        overflow-y: scroll !important;
        position: relative;
        z-index: 9999;
    }

    .search-bottom-row::-webkit-scrollbar {
        width: 10px !important;
    }

    .search-bottom-row::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgb(209 221 219) !important;
    }

    .search-bottom-row::-webkit-scrollbar-thumb {
        background-color: #03e1bcc2 !important;
        outline: 1px solid #03e1bc8f !important;
    }

    .row.search-bottom-row.activon {
        width: 37%;
    }

    .row.search-bottom-row.activon>div {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row.search-bottom-row.activon .main_img {
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        max-width: 100%;
    }

    .row.search-bottom-row.activon .main_img img:first-child {
        min-height: 225px;
        max-height: 225px;
        object-fit: cover;
    }

    .map {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
    }
}

@media (max-width:767px) {
    .map-filter.active {
        position: unset;
        width: 100%;
    }

    .map {
        position: unset !important;
    }

    .search_bar form input {
        position: relative;
        z-index: 22;
    }

    .search-listing .sidebar-wrapper.active #close_filter {
        z-index: 999;
        top: 26px;
        right: 17px;
        left: auto;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 16px;
    }

    .scroll_filters {
        height: 40vh;
        overflow-y: scroll;
    }

    .scroll_filters::-webkit-scrollbar {
        width: 10px !important;
    }

    .scroll_filters::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgb(209 221 219) !important;
    }

    .scroll_filters::-webkit-scrollbar-thumb {
        background-color: #03e1bcc2 !important;
        outline: 1px solid #03e1bc8f !important;
    }

    .search_bar form input {
        padding-left: 13px;
        font-size: 12px;
        width: calc(100% - 20px);
    }

    .search_bar form input::-webkit-input-placeholder {
        /* Edge */
        font-size: 12px;
    }

    .search_bar form input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 12px;
    }

    .search_bar form input::placeholder {
        font-size: 12px;
    }

    .mapboxgl-popup-content {
        left: -20px;
    }

    .mapboxgl-popup-content h3 {
        padding: 8px 0;
        font-size: 12px !important;
    }

    .row.top_filter {
        position: relative;
        z-index: 9999;
    }

    .map-filter.active .mapouter {
        overflow: hidden;
    }
}
</style>
